/**************************************************************************************************** 
 * SPLIT SCREEN LIBRARY
****************************************************************************************************/
.split {
	display: flex;
	flex-direction: row;
	height: 100%;

	&.split-content {
		height: 100%;
	}
}

.pane {
	height: 100%;
	overflow: auto;

	&.pane-content {
		height: 100%;
	}
}

.gutter {
	background-color: #eee;
	background-repeat: no-repeat;
	background-position: 50%;

	&.gutter-horizontal {
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
		cursor: col-resize;
	}
}

/**************************************************************************************************** 
 * GREPTCHA BADGE
****************************************************************************************************/
.grecaptcha-badge {
	visibility: hidden !important;
}

/**************************************************************************************************** 
 * TEXT STYLES
****************************************************************************************************/

a {
	text-decoration: none;
}

.text-upper-spaced {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 12px;
}

.emphasise {
	font-weight: 600;
}

.heading-font {
	font-family: 'Poppins', Arial, sans-serif !important;
	font-weight: 600 !important;

	&.with-icon {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		& .icon {
			margin-right: 5px;
		}

		& .text {
			text-overflow: ellipsis;
			overflow: hidden;
			margin: 5px 0px;
		}
	}
}

/**************************************************************************************************** 
 * BOX SHADOWS
****************************************************************************************************/
.box-shadow1 {
	box-shadow: 0 24px 48px rgb(17 16 62 / 12%) !important;
}

.box-shadow2 {
	box-shadow: 0 0 35px rgb(0 0 0 / 10%) !important;
}

.box-shadow3 {
	box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

/**************************************************************************************************** 
 * BOX SHADOWS
****************************************************************************************************/
