.markdown-body .highlight pre,
.markdown-body pre {
	padding: 0;
	background-color: #fff;
}

.katex-display > .katex {
	text-align: left !important;
}

.katex .katex-html > .newline {
	height: 15px !important;
}

.markdown-body img {
	min-width: 100%;
	position: relative;
}

@keyframes pulse-black {
	0% {
		/* transform: scale(0.95); */
		background-color: #ab3693;
		/* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); */
	}

	70% {
		/* transform: scale(1); */
		background-color: #07003c;
		/* box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); */
	}

	100% {
		background-color: #ab3693;
		/* transform: scale(0.95); */
		/* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
	}
}

.markdown-body div {
	margin-bottom: 10px;
}

.markdown-body img[alt]:after {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: 300px;
	font-family: 'Helvetica';
	font-weight: 300;
	line-height: 2;
	text-align: center;
	background-color: #000;
	border-radius: 10px;
	content: 'Error Loading Image';
	color: #fff;
	font-style: italic;
}
